export const getScrollEvent = ({ scrollPercentagesHit = [] }) => {
	return scrollPercentagesHit.map((scrollTopPercentage) => {
		return {
			event: `interaction.scroll`,
			events: {
				category: 'interaction.scroll',
				action: 'scroll',
				label: `${scrollTopPercentage}%`,
			},
		};
	});
};
