'use client';

import { useCookieConsent } from './useCookieConsent';
import { PropsWithChildren } from 'react';

export const ShowCookieAlertClient = ({ children }: PropsWithChildren) => {
	const [cookieConsent] = useCookieConsent();
	if (cookieConsent == null) return null;

	if (cookieConsent !== 'unknown') return null;

	return <>{children}</>;
};
