import { createSlice } from '@reduxjs/toolkit';

import {
	LOCATION_CHANGE,
	LOCATION_DATA_LOADED,
} from '@/globals/routing/location/locationChangeActions';
import { HrefKind } from '@/globals/routing/link/functions/getLinkInfo';

type LocationSliceState = {
	loading: boolean;
	error: number | null;
	linkType: string | null;
};

type LocationChangeAction = {
	type: typeof LOCATION_CHANGE;
	linkMeta: { linkType: string };
	hrefKind: HrefKind;
	href: string;
};

export const locationSlice = createSlice({
	name: 'location',
	initialState: (): LocationSliceState => ({
		loading: false,
		error: null,
		linkType: null,
	}),
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(LOCATION_DATA_LOADED, (state) => {
				state.loading = false;
				state.error = null;
			})
			.addCase(
				LOCATION_CHANGE,
				(state, { linkMeta, hrefKind }: LocationChangeAction) => {
					if (hrefKind === 'INTERNAL') {
						// We only have a finishInternalPageLoad with internal rerouting, which will set loading to false again
						state.loading = true;
					} else {
						state.loading = false;
					}
					state.linkType = linkMeta?.linkType;
				}
			);
	},
});
