'use client';

import { closeIcon } from '@/uiIcons/closeIcon.css';
import { classes } from '@/uiPrimitives/base/classes.helpers';
import { box } from '@/uiPrimitives/layout/box';
import { pageGrid } from '@/uiPrimitives/layout/pageGrid/pageGrid';
import { placement } from '@/uiPrimitives/layout/placement.css';
import { shelf } from '@/uiPrimitives/layout/shelf';
import { icon } from '@/uiPrimitives/media/icon';
import { useState } from 'react';
import { addClosedBannerToCookie } from './alertBannerCookies';
import { Translation } from '@/globals/translate/translations';

export const AlertBannerClient = ({
	children,
	alertID,
	closeAlertTranslation,
}: {
	children: React.ReactNode;
	alertID: string;
	closeAlertTranslation: Translation<'Close alert'>;
}) => {
	const [showing, setShowing] = useState(true);

	if (!showing) return null;

	return (
		<aside
			data-nosnippet
			className={classes(
				box({
					backgroundColor: 'negativeYellow',
				}),
				pageGrid({})
			)}
		>
			<div
				className={classes(
					shelf({
						gridTemplateColumns: 'minmax(0, 1fr) auto',
						gap: '3 | h1',
					})
				)}
			>
				{children}
				<button
					className={classes(
						placement({
							alignSelf: 'end',
							justifySelf: 'end',
						}),
						box({
							backgroundColor: 'positiveBlue',
							padding: '-1 | small',
						})
					)}
					aria-label={closeAlertTranslation}
					onClick={() => {
						addClosedBannerToCookie(alertID);
						setShowing(false);
					}}
				>
					<i
						className={classes(
							icon({
								icon: closeIcon,
								inlineSize: '0 | p',
								blockSize: '0 | p',
							})
						)}
					/>
				</button>
			</div>
		</aside>
	);
};
