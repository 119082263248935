import { getCookie } from '@/globals/helpers/cookie.helpers';

export const alertIDSeparator = ',';

export const alertCookieName = 'closedBannerAlerts';

export const addClosedBannerToCookie = (alertID: string) => {
	const currentClosedBannerAlertsCookie = getCookie(alertCookieName);
	const closedBannerAlertIDs = currentClosedBannerAlertsCookie
		? currentClosedBannerAlertsCookie.split(',')
		: [];

	const closedBannerAlerts = [alertID, ...closedBannerAlertIDs.slice(0, 19)];

	document.cookie = `${encodeURIComponent(
		alertCookieName
	)}=${encodeURIComponent(
		closedBannerAlerts.join(',')
	)}; path=/; max-age=31536000`;
};
