import { objectFromEntries } from 'tsafe';

export const getCookies = (): Record<string, string | undefined> => {
	const entries = document.cookie.split('; ').map((cookie) => {
		const [key, value] = cookie
			.split('=')
			.map((entry) => decodeURIComponent(entry));
		return [key, value] as const;
	});
	return objectFromEntries(entries);
};

export const getCookie = (name: string) => {
	const cookies = getCookies();

	return cookies[name];
};
